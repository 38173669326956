/* eslint-disable no-undef */
import React from "react"
import TemplatePage from "@components/pageColombia/pageWhatsappPrecio/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
    <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Precios de la API de WhatsApp Business en Colombia 2025 [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/co/whatsapp-business-api/`}
      />
      <link rel="alternate" hreflang="es-CO" href="https://beexcc.com/co/whatsapp-business-api/" />
      <link rel="alternate" hreflang="x-default" href="https://beexcc.com/co/whatsapp-business-api/" />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Conoce los detalles sobre los costos de la API de WhatsApp Business en Colombia y cómo se estructuran según las necesidades de cada empresa."
      />

      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Precios de la API de WhatsApp Business en Colombia 2025"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/co/whatsapp-business-api/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Conoce los detalles sobre los costos de la API de WhatsApp Business en Colombia y cómo se estructuran según las necesidades de cada empresa."
      />
    </Helmet>
    <TemplatePage location={location} />
  </div>
)

export default IndexPage

